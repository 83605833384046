import React from "react"
import styled from "@emotion/styled"

import { BigPillButton } from "../../components/button"
import Card from "../../components/card"
import Piggybank from "../../components/icons/piggybank"
import Timer from "../../components/icons/timer"
import { RoiAnimationBar } from "../../components/roi/roiAnimationBar"
import { RoiCalculator } from "../../components/roi/roiCalculator"
import { PieChart } from "../../components/pieChart"
import Typography from "../../components/typography"
import { useRoiContext } from "../../contexts/roiContext"
import colors from "../../styles/colors"
import Global from "../../styles/global"
import { currencySymbol } from "../../utils/currency"
import { trackEvent } from "../../utils/trackEvent"
import SEO from "../../components/seo"
import DemoContext from "../../contexts/demo"
import { BackTo } from "../../components/backTo"

const ContentWrapper = styled.div`
  margin: 0 auto;
  margin-top: 80px;
`

const TextTypo = styled(Typography)`
  color: ${colors.darkgrey};
`

const ResultCard = styled(Card)`
  @media screen and (min-width: 769px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
`
const MakeItYourRealityCard = styled(Card)`
  text-align: center;
  @media screen and (min-width: 769px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`
const Description = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    max-width: 400px;
    margin-right: 3rem;
  }
`

const MakeItYourReality = () => {
  const { open: openDemo } = React.useContext(DemoContext)

  return (
    <MakeItYourRealityCard variant="primary" noMinHeight>
      <Typography
        variant="h2"
        as="h2"
        style={{
          fontSize: "40px",
          fontWeight: 700,
          color: colors.white,
          marginBottom: "2rem",
        }}
      >
        Refine this calculation with our experts
      </Typography>
      <BigPillButton
        onClick={e => {
          openDemo(e)
          trackEvent({
            category: "sign_up_redirect",
            label: "roi result try mavenoid now",
            siteSection: "roi_result_make_it_reality",
          })
        }}
        variant="white"
        style={{
          width: "100%",
          height: "90px",
          outline: "none",
          maxWidth: "420px",
        }}
      >
        Build a custom impact analysis
      </BigPillButton>
    </MakeItYourRealityCard>
  )
}

const ROI = () => {
  const {
    companyName,
    serviceAgents,
    currency,
    costSavedYearly,
    costPerCase,
    costPerCaseSaved,
    savedHours,
    workingDaysPerMonth,
    workingHoursPerDay,
    calcRoi,
  } = useRoiContext()

  React.useEffect(() => {
    calcRoi()
  }, [calcRoi])

  return (
    <>
      <BackTo linkTo="roi" variant="dark" />
      <SEO title="Results" enableCookiebot />
      <Global bg="white" />
      <ContentWrapper>
        {/* ************************* */}
        <Card center variant="primary" noMinHeight>
          <Typography
            variant="h2"
            as="h1"
            style={{ fontWeight: 700, color: colors.white }}
          >
            {companyName || "You"} could save approximately
            {` ${currencySymbol[currency]}${parseFloat(costSavedYearly)
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} `}
            per year with support automation through Mavenoid
          </Typography>
        </Card>
        {/* ************************* */}
        <ResultCard variant="lightgrey">
          <Description>
            <Piggybank
              color={colors.darkgrey}
              style={{ width: "46px", marginBottom: "2rem" }}
            />
            <TextTypo variant="h3" style={{ fontWeight: 700 }}>
              You can lower your cost per customer contact (CPC) from{" "}
              {`${currencySymbol[currency]}${parseFloat(costPerCase).toFixed(
                1
              )} `}
              to
              {` ${currencySymbol[currency]}${parseFloat(
                costPerCase - costPerCaseSaved
              ).toFixed(1)}`}
            </TextTypo>
            <TextTypo
              variant="bodyLarge"
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
            >
              Mavenoid helps brands reduce overhead costs with support
              automation that scales efficiently, and allows customers to
              resolve their issues without contacting service agents. As a
              result, you spend less money to resolve your incoming support
              inquiries.
            </TextTypo>
          </Description>
          <RoiAnimationBar
            value={parseFloat(
              costPerCase !== 0
                ? (1 - (costPerCase - costPerCaseSaved) / costPerCase) * 100
                : 0
            ).toFixed(0)}
          />
        </ResultCard>
        {/* ************************* */}
        <ResultCard variant="lightgrey">
          <Description>
            <Timer
              color={colors.darkgrey}
              style={{ width: "40px", marginBottom: "2rem" }}
            />

            <TextTypo variant="h3" style={{ fontWeight: 700 }}>
              Your team can get back
              {` ${parseFloat(savedHours)
                .toFixed(0)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} `}
              hours per year
            </TextTypo>
            <TextTypo
              variant="bodyLarge"
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
            >
              By providing self-service support to customers, companies reduce
              the number of chats and phone calls, opening up time for agents to
              focus on complex troubleshooting instead of processing repetitive
              requests.
            </TextTypo>
          </Description>

          <PieChart
            max={workingHoursPerDay}
            min={0}
            value={parseFloat(
              savedHours / (serviceAgents * 12 * workingDaysPerMonth)
            ).toFixed(0)}
          />
        </ResultCard>
        {/* ************************* */}
        <MakeItYourReality />
        {/* ************************* */}
        <RoiCalculator />
        {/* ************************* */}
        <MakeItYourReality showDescription />
      </ContentWrapper>
    </>
  )
}

export default ROI
